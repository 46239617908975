import * as React from 'react'
import * as ReactDOM from 'react-dom'

let script = document.createElement('script')
let div = document.createElement('div')
const appendScript = (name: string) => {
  if (document.body.contains(div)) {
    document.body.removeChild(div)
  }
  if (document.body.contains(script)) {
    document.body.removeChild(script)
  }
  script = document.createElement('script')
  div = document.createElement('div')
  script.src = `${window.location.href}${name}.js`
  div.id = `${name}`
  document.body.appendChild(div)
  setTimeout(() => document.body.appendChild(script), 200)
}

interface ENV {
  widgetNames: string[]
}

export const WidgetsOverview = () => {
  const { widgetNames } = (process.env as undefined) as ENV
  const [activeWidgetName, setActiveWidgetName] = React.useState(widgetNames[0])
  React.useEffect(() => {
    appendScript(activeWidgetName)
  }, [activeWidgetName])
  return (
    <div>
      {widgetNames.map(widgetName => (
        <button onClick={() => setActiveWidgetName(widgetName)} key={widgetName}>
          {widgetName}
        </button>
      ))}
      <div>
        <code>{`<script src="${window.location.href}${activeWidgetName}.js" async defer ></script>`}</code>
        <code>{`<div id="${activeWidgetName}"></div>`}</code>
      </div>
    </div>
  )
}

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(<WidgetsOverview />, document.getElementById('root'))
})
